
import { defineComponent, computed, ref, reactive, watch } from "vue";
import useModel from "@/hooks/useModel";
import * as API from "@/API/checking/spotChecking";
import { message } from "ant-design-vue";
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import useThousands from "@/utils/payment/useThousands";

type Key = ColumnProps['key'];
export default defineComponent({
  name: "App",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    packageCodeId: {
      required: true,
      type: String,
      defaule: ''
    }
  },
  setup(props, context) {
    const _visible = useModel(props, "visible");
    const confirmColumns = computed(() => {
      return [
        { title: "Program Code", dataIndex: "programCode" },
        { title: "Program Name", dataIndex: "programName" },
        { title: "Offer Type", dataIndex: "offerType" },
        { title: "Dealer No.", dataIndex: "dealerNo", customRender: useThousands(), align: 'right' },
        { title: "VIN No.", dataIndex: "vinNo", customRender: useThousands(), align: 'right' },
        { title: "Claim No.", dataIndex: "claimNo", customRender: useThousands(), align: 'right' }
      ]
    })
    type TableData = {
      programOfferTypeId: string;
      programCode: string;
      programName: string;
      offerType: string;
      dealerNo: string;
      vinNo: string;
      claimNo: string;
    }
    const confirmData = ref<TableData[]>()
    const rowSelection = reactive<{ selectedRowKeys: Key[]; selectedRowData: TableData[]; onChange: Function;columnWidth: string }>({
        onChange: (selectedRowKeys: Key[], selectedRows: TableData[]) => {
            rowSelection.selectedRowKeys = selectedRowKeys;
            rowSelection.selectedRowData = selectedRows;
        },
        selectedRowKeys: [],
        selectedRowData: [],
        columnWidth: '30px'
    })
    watch(() => props.visible, () => {
      if (props.packageCodeId !== '') {
        API.getConfirmProgramList(props.packageCodeId).then(data => {
          confirmData.value = data
        })
      }
    })
    const handleCancel = () => {
      _visible.value = false;
      confirmData.value = []
      rowSelection.selectedRowKeys = []
      rowSelection.selectedRowData = []
    }
    const handleConfirm = () => {
      if (rowSelection.selectedRowKeys.length === 0) {
        message.warning('Please select the data first')
        return
      }
      API.confirmProgram(props.packageCodeId, rowSelection.selectedRowKeys).then(res => {
        if (res.code === '0') {
          message.success('Successfully Confirm!')
          _visible.value = false
          rowSelection.selectedRowKeys = []
          rowSelection.selectedRowData = []
          context.emit('confirm-success')
        } else {
          message.warning(res.message)
        }
      })
    }
    return {
      _visible,
      handleCancel,
      handleConfirm,
      confirmColumns,
      confirmData,
      rowSelection
    };
  },
});
